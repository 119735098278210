import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueMeta from 'vue-meta'
import VueMask from 'v-mask'
import VueHtmlToPaper from 'vue-html-to-paper'

Vue.use(VueMeta)
Vue.use(VueMask)
Vue.use(VueHtmlToPaper, {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "/landscape.css"
  ]
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

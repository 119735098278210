import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Vendese.vue'),
  },
  {
    path: '/imprimir/placa/vende-se',
    name: 'vende-se',
    component: () => import('../views/Vendese.vue'),
  },
  {
    path: '/imprimir/placa/aluga-se',
    name: 'aluga-se',
    component: () => import('../views/Alugase.vue'),
  },
  {
    path: '/imprimir/placa/mascara-obrigatoria',
    name: 'mascara-obrigatoria',
    component: () => import('../views/Mascara.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

<template>
  <v-app id="app">
    <v-app-bar
      app
      color="white"
      
    >
      <div class="title-page">
        <h1>IMPRIMIR PLACA ONLINE GRÁTIS</h1>
      </div>
      <v-spacer></v-spacer>
      <v-tabs
        right
        color="grey darken-1"
      >
        <v-tab
          v-for="link in links"
          :key="link.url"
          :to="link.url"
        >
          <h2>{{ link.label }}</h2>
        </v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main class="grey lighten-3 my-3 px-3 pb-0 mb-0" style="height: 100%;">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      links: [
        {label: 'Vende-se', url: '/imprimir/placa/vende-se'},
        {label: 'Aluga-se', url: '/imprimir/placa/aluga-se'},
        {label: 'Máscara obrigatória', url: '/imprimir/placa/mascara-obrigatoria'},
      ],
    }),
  }
</script>

<style lang="scss">
@media print{@page {size: landscape}}

.title-page {
  width: 463px;
  display: inherit;

  h1 {
    font-size: 20px;
  }
}

.v-tab h2 {
  font-size: 0.875rem !important;
}

.v-app-bar {
  box-shadow: 0px 7px 25px 0px rgb(0 0 0 / 10%) !important;
}
</style>
